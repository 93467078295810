.gallery-container.container {
  padding: 5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 9rem;
}

.gallery-container.container .flexy {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-container * {
  max-height: 27.5rem;
}

.gallery-container .grid-2 {
  grid-column: 2 span;
}

@media screen and (max-width: 1300px) {
  .gallery-container.container {
    grid-template-columns: repeat(2, 1fr);
  }

  .gallery-container.container .flexy.hidden-1 {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .gallery-container.container {
    grid-template-columns: auto;
    gap: 10rem;
    padding: 10rem;
  }

  .gallery-container.container .gallery-text {
    justify-self: center;
  }

  .gallery-container.container .flexy.baff {
    order: 1;
  }

  .gallery-container.container .final {
    order: 2;
  }
}

@media screen and (max-width: 450px) {
  .gallery-container.container {
    padding: 2.5rem;
  }
}
