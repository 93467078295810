.header-container {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 500;
  left: 0;
  right: 0;
  background: rgb(246, 246, 246, 0.8);

  padding: 1.5rem 3rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
}
