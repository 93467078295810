.fournisseur-section {
  background: #394285;
  color: #f6f6f6;
}

.fournisseur-section .title {
  color: #f6f6f6;
}

.fournisseur-section .container {
  min-height: 40rem;
  display: grid;
  align-items: center;
  grid-template-columns: 60% 40%;
  border-radius: 1rem;
  overflow: hidden;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: #f6f6f6;
  color: #333;
}

.fournisseur-section .container .text {
  padding: 5rem;
  font-size: 1.8rem;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.img-container {
  height: 100%;
  background: #394285;
}

.img-container img {
  height: 100%;
}

.fournisseur-section .container .text .fournisseur-section-btn {
  font-size: 2.2rem;
}

@media screen and (max-width: 900px) {
  .fournisseur-section .container {
    width: 80%;
    grid-template-columns: 1fr;
  }

  .fournisseur-section .container .text {
    font-size: 2rem;
    line-height: 1.6;
    text-align: center;
  }

  .fournisseur-section .container .img-container {
    order: -1;
  }

  .fournisseur-section .container .text .fournisseur-section-btn {
    margin-left: 0;
    border-radius: 0.5rem;
  }
}
