.hero-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  background: url('../../assets/hero_section.png') no-repeat top left / 100vw
    100vh;
}

.hero-section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.4);
}

.hero-section .text {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;

  padding: 2.5rem;
  margin-top: 10rem;
  max-width: 50%;
  text-align: center;

  background: rgba(246, 246, 246, 0.7);
  color: #394285;
  z-index: 5;
}

.hero-section .text h2 {
  font-size: 3.8rem;
  font-weight: 700;
}

.hero-section .text p {
  font-size: 2.4rem;
  line-height: 1.6;
  max-width: 80%;
}

@media screen and (max-width: 1270px) {
  .hero-section .text {
    min-width: 80%;
  }
}
