.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.navbar-container.hidden {
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

.navbar-container a {
  padding: 1.5rem 3rem;
  border-radius: 50px;
}

.navbar-container a:hover,
.navbar-container a.active {
  color: #f6f6f6;
  background: #2a3061;
}

.burger-menu {
  display: none;
  order: 5;
}

.burger-menu .burger-menu-icon {
  font-size: 9rem;
}

@media screen and (max-width: 1270px) {
  .navbar-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    gap: 0;
    flex-direction: column;

    background: rgb(246, 246, 246, 0.8);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
      rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }

  .navbar-container a {
    width: 100%;
    border-radius: 0;
    font-weight: 700;
    color: #2a3061;
  }

  .burger-menu {
    display: block;
  }
}
