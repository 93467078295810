.footer-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  border-top: 0.1rem solid #2a3061;
}

.footer-container .text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.footer-container .text-container .title {
  color: #2a3061;
  font-size: 2.8rem;
  margin-bottom: 1rem;
}

.footer-container .text-container .sous-title {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.footer-container .text-container p {
  font-size: 1.4rem;
}

@media screen and (max-width: 800px) {
  .footer-container .logo-container.hidden {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .footer-container {
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }

  .footer-container .hidden {
    display: none;
  }
}
